<template>
	<div class="container">
		<el-form :model="ruleForm" :rules="rules"  ref="ruleForm" label-width="320px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item
						label="商品售罄后，是否显示"
						:inline-message="true"
						prop="isShowZeroStockGoods"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowZeroStockGoods" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowZeroStockGoods" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>

                    <el-form-item
						label="首页优惠券入口是否开启"
						:inline-message="true"
						prop="isCouponSetting"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isCouponSetting" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isCouponSetting" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
                    <el-form-item
                        label="优惠券悬浮图片："
                        :inline-message="true"
                        prop="couponIcon"
                        :rules="[{required:true,message:'优惠券悬浮图片不能为空',trigger: 'blur'}]"
                        v-if="ruleForm.isCouponSetting == 1"
                    >
                        <el-upload
                            class="avatar-uploader"
                            :action="imgApi"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                        >
                            <img
                                v-if="ruleForm.couponIcon"
                                :src="ruleForm.couponIcon+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
                                style="width:146px;height:146px;"
                                class="avatar"
                            />
                            <i
                                v-else
                                class="el-icon-plus"
                                style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
                            ></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item
						label="商详页展示悬浮滚动栏"
						:inline-message="true"
						prop="isShowPush"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowPush" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowPush" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
                    <el-form-item
                        label="下单轮播用户数量"
                        :inline-message="true"
                        prop="isShowPushNum"
                        v-if="ruleForm.isShowPush == 1"
                    >
                        <el-input
                            v-model="ruleForm.isShowPushNum"
                            style="width:80px;"
                            @input="formatParseInt(ruleForm,'isShowPushNum')"
                        ></el-input>&nbsp; 
                    </el-form-item>
                    <el-form-item
                        label="真实销量达到多少时，开启滚动"
                        :inline-message="true"
                        prop="isSaleCount"
                        v-if="ruleForm.isShowPush == 1"
                        :rules="[{required:true,message:'开启条件不能为空，0为不限制',trigger: 'blur'}]"
                    >
                        <el-input
                            v-model="ruleForm.isSaleCount"
                            style="width:80px;"
                            @input="formatParseInt(ruleForm,'isSaleCount')"
                        ></el-input>&nbsp; 
                    </el-form-item>

                    <el-form-item
						label="分类页面是否显示子分类"
						:inline-message="true"
						prop="isShowChildCategory"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowChildCategory" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowChildCategory" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
					<el-form-item
						label="分类页面是否显示全部商品类目"
						:inline-message="true"
						prop="isShowAllCategory"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowAllCategory" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowAllCategory" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
                    <el-form-item
						label="是否显示微信登录"
						:inline-message="true"
						prop="isShowWx"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowWx" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowWx" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
                    <el-form-item
						label="是否显示苹果登录"
						:inline-message="true"
						prop="isShowApple"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.isShowApple" label="1">是</el-radio>
							<el-radio v-model="ruleForm.isShowApple" label="0">否</el-radio>
						</el-form-item>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
import { getOtherSetting, saveOtherSetting } from "@/api/setting";
import config from '@/config/index'
export default {
    
	data () {
        var checkIsShowPushNum = (rule, value, callback) => {
            if (0 <= value && value <= 100) {
                return callback();
            } else if (value > 100) {
                return callback(new Error('不能超过100条！'));
            }
        };
		return {
			ruleForm: {
                isShowZeroStockGoods: "1",   //商品售罄后，是否显示
                isCouponSetting:"1",    //优惠券入口开关
                couponIcon:'',   //优惠券图片路径
                isShowPush:"1", //是否显示商详滚动
                isShowPushNum:10,   //滚动数量
                isSaleCount:"2",   //真实销量达到多少时，开启滚动
                isShowChildCategory:"1",  //是否显示分类商品
				isShowAllCategory:'0', //是否显示全部商品类目
                isShowWx:'0',       //微信登录开关
                isShowApple:'0',        //苹果登录开关
			},
            loading: false,  //保存接口防止多次点击
            imgApi: config.UPLOAD_IMG,
            rules:{
                isShowPushNum: [{
                    validator: checkIsShowPushNum,
                    trigger: 'change'
                }],
            }
		};
	},
	computed: {

	},
	created () {
		this.getOtherSetting();
	},

	methods: {
		//获取详细数据
		async getOtherSetting () {
			let res = await getOtherSetting();
			this.ruleForm = res.data;
			if(res.data.isShowAllCategory==null){
				this.ruleForm.isShowAllCategory='0';
			}
            if(res.data.isShowWx==null){
				this.ruleForm.isShowWx='0';
			}
            if(res.data.isShowApple==null){
				this.ruleForm.isShowApple='0';
			}
		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
            this.loading = true
            if (!this.ruleForm.isShowPushNum) {
                this.ruleForm.isShowPushNum = 0;
            }
			let res = await saveOtherSetting(this.ruleForm);

			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}else{
                this.$message({
					type: 'error',
					message: res.alertMsg
				});
            }
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getOtherSetting();
        },
        // 上传规格图成功钩子
		handleAvatarSuccess (res) {
            this.ruleForm.couponIcon = res.data[0];
            console.log(this.ruleForm.couponIcon,res);
			return true;
        },
        // 上传规格图前钩子
		beforeAvatarUpload (file) {
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}
			return isLt3M;
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
</style>
